import React from 'react';
import Twitter from '../../assets/homepage/twitter.svg';
import Linkedin from '../../assets/homepage/linkedin.svg';


export default function Footer() {
  return (
    <footer>
        <div className="gpt__container footer__flex">
          <p className="footer__text">
            ©2023 SameAI LLC. 525 Route 73 North Ste 104, 08053 Marlton, NJ. -{" "}
            <a href="/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </p>
          <div className="footer__social">
            <a href="https://twitter.com/Sameai_io" target="_blank" rel="noreferrer">
              <img
                src={Twitter}
                alt="Twitter"
                className="twitter"
              />
            </a>
            <a href="https://www.linkedin.com/company/sameai/" target="_blank" rel="noreferrer">
              <img
                src={Linkedin}
                alt="Linkedin"
                className="Linkedin"
              />
            </a>
          </div>
        </div>
      </footer>
  )
}
