import React, { useContext } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { requestPasswordReset } from "../../api/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//validation
const emailSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  
});



export default function RequestResetPasswordForm() {

  const [successMessage, setSuccessMessage] = useState(false)

  const navigate = useNavigate();

  async function onSubmit(values) {
    try {
      const res = await requestPasswordReset(values); //set password POST here
      if (res.status === 200) {
        setSuccessMessage(true)
      }
    } catch(error){
      console.log(error)
      toast.error(error)
    }
    
  }

  return (
    <div className="login__form">
      {/* Formik */}
        
        <Formik
          initialValues={{
            email: ""
          }}
          validationSchema={emailSchema}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Form>
                {/* email field */}
              <label htmlFor="email">Email address</label>
              <Field
                type="text"
                name="email"
                label="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="Email address"
                className={errors.email && touched.email ? "field__error" : ""}
              />
              {errors.email && touched.email ? (
                <span className="form__error">{errors.email}</span>
              ) : null}

                <button type="submit" onClick={handleSubmit}>Request password reset</button>
                {/*  */}
                {/* <a href="/" className="signin__notice" onClick={()=> {setResetPassword(false)}}>Already have an account? Sign in</a> */}
                {/* password reset notice - once email has been sent*/}
                {/* <span className="psw-reset__notice">Check your email for the password reset link</span> */}
              </Form>
              {successMessage && <p className="success-message">Your request has been received. If an account is associated with the e-mail address you entered you will receive a notification with further instructions to reset your password.</p>}
            </>
          )}
        </Formik>
    </div>
  );
}
