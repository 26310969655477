import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import Navbar from "../components/Navbar";
import { AuthContext } from "../context/AuthContext";
//import purple from '../assets/vectors/purple.svg';
//import blue from '../assets/vectors/blue.svg';
//import green from '../assets/vectors/green.svg';
import { Link } from "react-router-dom";


export default function LogIn() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("gpt-auth"));
    if (auth) {
      const currentTimeStamp = Math.floor(new Date().getTime() / 1000);
      const tokenObj = JSON.parse(window.atob(auth.accessToken.split(".")[1]));
      const tokenIsNotExpired = tokenObj.exp > currentTimeStamp;
      if (tokenIsNotExpired) {
        setAuthState(auth);
        navigate("/search");
      }
    }
  }, [authState]);

  function printLoginTitle(){
    const loginUrl = window.location.pathname
    if (loginUrl === '/'){
      return(
        <h1 className="login__title">Log in</h1>
      )
    } else if (loginUrl === '/login/success'){
      return(
        <h1 className="login__title">Your e-mail has been confirmed. You can now login</h1>
      )
    } else if (loginUrl === '/login/reset-success'){
      return(
        <h1 className="login__title">The password reset was successful. You can now login</h1>
      )
    }
  }


  return (
    <>
      <Navbar />
      <div className="login__container">
       {/* <img className="purple" src={purple} alt="color"></img>
        <img className="green" src={green} alt="color"></img>
	   <img className="blue" src={blue} alt="color"></img>*/}
        {printLoginTitle()}
        <LoginForm />
        <Link to="/register">
          { window.location.pathname !== '/login/success' && <h5 className="register__link">Don't have an account yet? Register for free here</h5>}
        </Link>
      </div>
      <Footer />
    </>
  );
}
