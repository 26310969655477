import { protectedFetch,  METHODS, ENDPOINT, protectedFetchFile } from "./general";
import { PROD_URL } from '../config/config_local';

//GET CONTENTS
export async function getContentByType(type) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}?contentType=${type}`,
    METHODS.GET
  );
  return response;
}

//GET ALL CONTENTS
export async function getContents() {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}`,
    METHODS.GET
  );
  return response;
}



//restore contents
export async function restoreContent(id) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}`,
    METHODS.PATCH,
    {
      isInTrash: false,
    }
  );
  return response;
}

//move content to trash
export async function trashContent(id) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}`,
    METHODS.PATCH,
    {
      isInTrash: true,
    }
  );
  return response;
}

//delete content
export async function deleteContent(id) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}`,
    METHODS.DELETE
  );
  return response;
}

// update title and tags
//https://www.recapgpt.com/api/content/${id}
export async function updateData(id, title, tags) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}`,
    METHODS.PATCH,
    {
      title: title,
      tags: tags //array ['firsttag' , 'secondtag' , 'thirdtag']
    }
  );
  return response;
}

// update transcript
export async function updateTranscript(id, transcript) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}`,
    METHODS.PATCH,
    {
      transcript: transcript,
      updateDate: new Date()
    }
  );
  return response;
}

// update summary
export async function updateRecap(id, recap) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}`,
    METHODS.PATCH,
    {
      recap: recap,
    }
  );
  return response;
}

//download content
export async function downloadContent(id, type) {
  const response = await protectedFetchFile(
    `${PROD_URL}/${ENDPOINT.CONTENT}/${id}/${type}`,
    METHODS.GET,
    null,
    'octet-stream'
  );
  return response;
}
