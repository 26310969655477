import { useState, useEffect, useContext } from "react";
import Layout from "../components/Layout";
import avatarGPT from "../assets/vectors/avatar-gpt.svg";
import avatarUser from "../assets/vectors/user.svg";
import credit from "../assets/vectors/credit.svg";
import send from "../assets/vectors/send.svg";
import trash from "../assets/vectors/delete.svg";
import Instructions from "../components/Instructions";
import close from "../assets/vectors/close-video.svg";
import { doNotShowVideo } from "../api/user";
import { AuthContext } from "../context/AuthContext";


export default function Chat() {
  const conversation = [
    // {
    //   id: 1,
    //   text: "What is a Chatbot?",
    //   send: true,
    // },
    // {
    //   id: 2,
    //   text: "A chatbot is a computer program that simulates human conversation through voice commands or text chats or both. It can be integrated with various messaging platforms like Facebook Messenger, WhatsApp, WeChat, etc. and can be used for a variety of purposes, such as customer service entertainment, and e-commerce.",
    //   send: false,
    // },
    // {
    //   id: 3,
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris placerat quis ante sit amet faucibus. Pellentesque tincidunt, tortor id rhoncus consectetur, ante odio ullamcorper purus, et feugiat libero eros eget massa. Sed lectus justo, fermentum vel tortor id, ultrices porta erat. Nunc fermentum risus vel dignissim finibus.",
    //   send: true,
    // },
    // {
    //   id: 4,
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris placerat quis ante sit amet faucibus. Pellentesque tincidunt, tortor id rhoncus consectetur, ante odio ullamcorper purus, et feugiat libero eros eget massa. Sed lectus justo, fermentum vel tortor id, ultrices porta erat. Nunc fermentum risus vel dignissim finibus.",
    //   send: false,
    // },
    // {
    //   id: 5,
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris placerat quis ante sit amet faucibus. Pellentesque tincidunt, tortor id rhoncus consectetur, ante odio ullamcorper purus, et feugiat libero eros eget massa. Sed lectus justo, fermentum vel tortor id, ultrices porta erat. Nunc fermentum risus vel dignissim finibus.",
    //   send: true,
    // },
    // {
    //   id: 6,
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris placerat quis ante sit amet faucibus. Pellentesque tincidunt, tortor id rhoncus consectetur, ante odio ullamcorper purus, et feugiat libero eros eget massa. Sed lectus justo, fermentum vel tortor id, ultrices porta erat. Nunc fermentum risus vel dignissim finibus.",
    //   send: false,
    // },
    // {
    //   id: 7,
    //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris placerat quis ante sit amet faucibus. Pellentesque tincidunt, tortor id rhoncus consectetur, ante odio ullamcorper purus, et feugiat libero eros eget massa. Sed lectus justo, fermentum vel tortor id, ultrices porta erat. Nunc fermentum risus vel dignissim finibus.",
    //   send: true,
    // },
  ];

  const [input, setInput] = useState("");
  const [videoModal, setVideoModal] = useState(true);
  const {authState, setAuthState} = useContext(AuthContext);


  function handleInputChange(input) {
    setInput(input);
  }

  function closeVideo() {
    setVideoModal(false);
  }

  async function handleChangeCheckbox() {
    //chiamata API con PATCH su /me o /user
    try {
      await doNotShowVideo();
      const newAuthState = {
        ...authState,
        videoModal: false
      }
      setAuthState(newAuthState);
      localStorage.setItem("gpt-auth", JSON.stringify(newAuthState));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(!authState.videoModal){
      setVideoModal(false)
    } else {
      setVideoModal(true)
    }
  }, []);

  return (
    <>
      <Layout>
        <div className="dashboard__container">
          {/* component visile only at first access */}
          {/* <Instructions/> */}
          <div className="chat__wrapper">
            {/* chat history */}
            {conversation.length > 0 ? (
              <div className="chat__history">
                <div>
                  {conversation.map((item, index) => {
                    return (
                      <div className="chat__item" key={index}>
                        <img
                          src={item.send ? avatarGPT : avatarUser}
                          alt="Avatar"
                        ></img>
                        <p>{item.text}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Instructions />
            )}
            {/* buttons - hidden first release */}
            {/* <div className="chat__buttons-container">
              <button>Regenerate response</button>
              <button className="cancel-chat">
                <img src={trash} alt="delete"></img>
              </button>
            </div> */}
            {/* chat input - hidden first release */}
            {/* <div className="chat__input">
              <input
                value={input}
                onChange={(e) => handleInputChange(e.target.value)}
              />
              <div className="conversation__credits">
                <img src={credit} alt="credits"></img> 1
              </div>
              <button
                className="conversation__submit"
                //onClick={() => sendMessage()}
              >
                <img src={send} alt="send"></img>
              </button>
            </div> */}
          </div>
        </div>
        {videoModal && (
          <div className="overlay">
            <div className="video__wrapper">
              <div className="close__video" onClick={closeVideo}>
                <img src={close} alt="Close video"></img>
              </div>
              <video width="990" height="556" controls>
                <source
                  src="https://recapgpt.com/video/RecapGPT%20welcome%20video.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="video__checkbox">
                <input
                  type="checkbox"
                  value=""
                  onChange={handleChangeCheckbox}
                />
                <p>Don't show again</p>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
