import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";
//import recapGPTLogo from "../../assets/vectors/RecapGPT_logo-white.svg";
import recapGPTLogo from "../../assets/vectors/movimemo_logo.svg";
import search from "../../assets/vectors/search.svg";
import search_hover from "../../assets/vectors/search_hover.svg";
import trash from "../../assets/vectors/trash.svg";
import trash_hover from "../../assets/vectors/trash_hover.svg";
import link from "../../assets/vectors/link.svg";
import link_hover from "../../assets/vectors/link_hover.svg";
import star from "../../assets/vectors/star-icon.svg";
import hamburger from "../../assets/vectors/hamburger.svg";
import closeSidebar from "../../assets/vectors/closeSidebar.svg";
import userAvatar from "../../assets/vectors/userAvatar.svg";
import logout from "../../assets/vectors/sidebar-logout.svg";
import documents from "../../assets/vectors/documents.svg";
import documents_hover from "../../assets/vectors/documents_hover.svg";
import workflows from "../../assets/vectors/conversations.svg";
import workflows_hover from "../../assets/vectors/conversations_hover.svg";

import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  const currentPage = location.pathname.slice(1);

  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);
  const { authState, setAuthState } = useContext(AuthContext);

  const navigate = useNavigate();

  //opening mobile sidebar
  function openSidebar() {
    setOpenMobileSidebar(true);
  }

  function renderCurrentSidebarItem(currentSidebarItem) {
    const formattedSidebarName = currentSidebarItem.name.split(" ").join("-").toLowerCase()
    if (location.pathname.includes(formattedSidebarName)) {
      return (
        <div className="sidebar__item sidebar__highlight">
          <img src={currentSidebarItem.icon_hover} alt="menu-icon"></img>
          <span>{currentSidebarItem.name}</span>
        </div>
      );
    } else {
      return (
        <div className="sidebar__item">
          <img src={currentSidebarItem.icon} alt="menu-icon"></img>
          <span>{currentSidebarItem.name}</span>
        </div>
      );
    }
  }

  const menuItems = [
    {
      icon: userAvatar,
      icon_hover: search_hover,
      name: authState.firstName +' '+ authState.lastName,
      link: {
        target: "_self",
        url: "/my-profile",
      },
    },
    {
      icon: search,
      icon_hover: search_hover,
      name: "Search",
      link: {
        target: "_self",
        url: "/search",
      },
    },
	{
      icon: documents,
      icon_hover: documents_hover,
      name: "Memos",
      link: {
        target: "_self",
        url: "/documents",
      },
    },
    {
      icon: workflows,
      icon_hover: workflows_hover,
      name: "Workflows",
      link: {
        target: "_self",
        url: "/workflows",
      },
    },
    {
      icon: trash,
      icon_hover: trash_hover,
      name: "Trash",
      link: {
        target: "_self",
        url: "/trash",
      },
    },
    {
      icon: link,
      icon_hover: link_hover,
      name: "Discord",
      link: {
        target: "_blank",
        url: "https://discord.com/invite/m78PArCrC2",
      },
    },
    {
      icon: link,
      icon_hover: link_hover,
      name: "Support",
      link: {
        target: "_blank",
        url: "#",
      },
    },
  ];

  //logging out
  function doLogOut() {
    localStorage.removeItem("gpt-auth");
    setAuthState({
      accessToken: null,
      isLoggedIn: false,
      firstName: null,
      lastName: null,
      email: null,
      userId: null,
      avatar: null,
      country: null,
      address: null,
      city: null,
      zipcode: null,
      videoModal: null,
    });
    navigate("/");
  }

  function getClassName(){
    if (location.pathname === '/my-profile'){
      return "user-box hover-background"
    } else {
      return "user-box"
    }
  }

  return (
    <>
      {/* desktop */}
      <div className="sidebar sidebar__desktop">
        <div className="logo__flex">
          <img src={recapGPTLogo} alt="MoviMemo"></img>
        </div>
        <div className="sidebar__menu">
          {/* user details */}



          {/* end user details */}
		  
          {/* menu items */}
          <div className="sidebar__list">
			{/* user 
            <Link to="/my-profile">
              <div className={getClassName()}>
                <img src={userAvatar} alt="avatar"></img>
                <span>
                  {authState.firstName} {authState.lastName}
                </span>
              </div>
            </Link>	*/}	  
            {/* menu list */}
            {menuItems.map((item, index) => {
              return (
                <Link
                  to={item.link.url}
                  target={item.link.target}
                  key={index + 1}
                  rel="nopeerer"
                >
                  {renderCurrentSidebarItem(item)}
                </Link>
              );
            })}

            {/* end menu list */}
          </div>
          {/* end menu items */}

          {/* user details */}

          <div className="user-list__container">
            {/* credits 
            <div className="credits-box">
              <div className="star__icon-box">
                <img src={star} alt="star-icon"></img>
              </div>
              <span>
                Available credits:{" "}
                {Intl.NumberFormat("en-US").format(authState.currentCredits)}
              </span>
              <Link to="/credits">
                <div className="credits__link">Manage credits</div>
              </Link>
            </div>*/}
            {/* logout */}
            <div className="logout__box" onClick={doLogOut}>
              <img src={logout} alt="Logout"></img>
              <span>Logout</span>
            </div>
          </div>

          {/* end user details */}
        </div>
      </div>
      {/* mobile */}
      <div className="sidebar__mobile">
        <div className="top-level__sidebar">
          <img
            src={hamburger}
            alt="open the menu"
            className="hamburger"
            onClick={() => openSidebar()}
          ></img>
          <img className="logo__mobile" src={recapGPTLogo} alt="RecapGPT"></img>
        </div>
        {/* sidebar */}
        {openMobileSidebar && (
          <div className="sidebar__mobile-container">
            <img
              src={closeSidebar}
              alt="close the menu"
              class="close-sidebar"
              onClick={() => setOpenMobileSidebar(false)}
            ></img>

            {/* menu items */}
            <div className="sidebar__list">
              {/* menu list */}
              {menuItems.map((item, index) => {
                return (
                  <Link
                    to={item.link.url}
                    target={item.link.target}
                    key={index + 2}
                    rel="nopeerer"
                  >
                    <div
                      className="sidebar__item"
                      style={{
                        background:
                          currentPage === item.name.toLowerCase()
                            ? "rgba(255, 255, 255, 0.1)"
                            : "",
                      }}
                    >
                      <img src={item.icon} alt="menu-icon"></img>
                      <span>{item.name}</span>
                    </div>
                  </Link>
                );
              })}

              {/* end menu list */}
            </div>
            {/* end menu items */}

            {/* user details */}
            <div className="user-list__container">
              {/* credits */}
              <div className="credits-box">
                <div className="star__icon-box">
                  <img src={star} alt="star-icon"></img>
                </div>
                <span>
                  Available credits:{" "}
                  {Intl.NumberFormat("en-US").format(authState.currentCredits)}
                </span>
                <Link to="/credits">
                  <div className="credits__link">Manage credits</div>
                </Link>
              </div>
              {/* profile */}
              <Link to="/my-profile">
                <div className={getClassName()}>
                  <img src={userAvatar} alt="avatar"></img>
                  <span>
                    {authState.firstName} {authState.lastName}
                  </span>
                </div>
              </Link>
              {/* logout */}
              <div className="logout__box" onClick={doLogOut}>
                <img src={logout} alt="Logout"></img>
                <span>Logout</span>
              </div>
            </div>

            {/* end user details */}
          </div>
        )}
        {/* end sidebar */}
      </div>
    </>
  );
}
