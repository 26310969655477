import React from 'react';
//import Logo from '../../assets/vectors/RecapGPT_logo-black.svg';
import Logo from '../../assets/vectors/movimemo_logo.svg';

export default function Navbar() {
  return (
    <nav>
        <div className="gpt__container navbar__flex">
          <a href="http://www.movimemo.com/"><img
            className="logo login__logo"
            src={Logo}
            alt="MoviMemo Logo"
          /></a>
          {/* <Link className="login" to="/">
            Log in{" "}
            <img className="arrow" src={Arrow} alt="arrow" />
          </Link> */}
        </div>
      </nav>
  )
}
