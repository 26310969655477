import React, { Fragment, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import close from "../../assets/vectors/close.svg";
import context from "../../assets/vectors/context.svg";
import summary from "../../assets/vectors/summary.svg";
import credit from "../../assets/vectors/credit.svg";
import send from "../../assets/vectors/send.svg";
import download from "../../assets/vectors/download.svg";
import ChatLoader from "../ChatLoader";
import EditableArea from "../EditableArea";
import OutsideClickHandler from "react-outside-click-handler";
import meetingManager from "../../assets/vectors/m_click.svg";
import meetingManagerDefault from "../../assets/vectors/m_default.svg";
import hrManager from "../../assets/vectors/chatIcon.svg";
import chatAvatar from "../../assets/vectors/chatIcon.svg";
import hrManagerDefault from "../../assets/vectors/chatIcon.svg";
import avatar from "../../assets/vectors/avatar.svg";
import clear from "../../assets/vectors/clear.svg"
import { formatDate } from "../../utils/dates";
//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContentTab({
  selectedContent,
  handleClose,
  handleSaveTranscript,
  handleSaveRecap,
  handleDownloadContent,
  currentConversation,
  handleClearConversation,
  handleSendConversation,
  isLoading,
}) {
  const location = useLocation();
  const contentMenu = [
    {
      id: 1,
      name: "Prompt",
      img: chatAvatar,
      tag: "AI copilot chat",
    },
    {
      id: 2,
      name: "Context",
      img: context,
      tag: "AI extracted context",
    },
    {
      id: 3,
      name: "Recap",
      img: summary,
      tag: "AI generated recap",
    },
    // {
    //   id: 4,
    //   name: "Template",
    //   img: template,
    //   tag: "Template",
    // },
  ];

  const [tabSection, setTabSection] = useState(contentMenu[0]);
  const [editable, setEditable] = useState(false);
  const [isUserUpdating, setIsUserUpdating] = useState(false);

  const [editableRecap, setEditableRecap] = useState();
  const [isUserUpdatingRecap, setIsUserUpdatingRecap] = useState(false);
  const [updatedRecap, setUpdatedRecap] = useState(false);
  const [updatedTranscript, setUpdatedTranscript] = useState("");
  const [inputConversation, setInputConversation] = useState("");

  const [saveReminder, setSaveReminder] = useState(false);

  const textarea = useRef(null);

  
  
  function handleClickOnMenu(item) {
    if (item.name !== "Template") {
      setTabSection(item);
    } else {
      window.open(
        "https://google.it", //link to form
        "_blank"
      );
    }
  }

  function handleUpdatedTranscript(transcript) {
    setUpdatedTranscript(transcript);
  }

  function makeTranscriptEditable() {
    setEditable(true);
    setIsUserUpdating(true);
    setUpdatedTranscript(selectedContent.transcript);
  }

  function handleUpdatedRecap(recap) {
    setUpdatedRecap(recap);
  }

  function onContentDownload() {
    let document = "";
    if (tabSection.id === 1) {
      document = "chatlog";
    } else if (tabSection.id === 2) {
      document = "transcript";
    } else if (tabSection.id === 3) {
      document = "recap";
    }
    handleDownloadContent(selectedContent, document);
  }

  function makeRecapEditable() {
    setEditableRecap(true);
    setIsUserUpdatingRecap(true);
    setUpdatedRecap(selectedContent.recap);
  }

  function onClose() {
    if (isUserUpdating || isUserUpdatingRecap) {
      setSaveReminder(true);
    } else {
      setTabSection(contentMenu[0]);
      //when closing contentTab set all edit variables to false
      setEditable(false);
      setEditableRecap(false);
      setIsUserUpdating(false);
      setIsUserUpdatingRecap(false);
      handleClose();
    }
  }

  function onSaveTranscript() {
    setEditable(false);
    setIsUserUpdating(false);
    setSaveReminder(false);
    handleSaveTranscript(selectedContent, updatedTranscript);
  }

  function onSaveRecap() {
    setEditableRecap(false);
    setIsUserUpdatingRecap(false);
    setSaveReminder(false);
    handleSaveRecap(selectedContent, updatedRecap);
  }

  function onSendConversation(e) {
    e.preventDefault();
    if(inputConversation !== ""){
      handleSendConversation(selectedContent, inputConversation);
    } else{
      toast.warning('Please provide a message')
    }
    setInputConversation("");
    //setTextareaHeight(48);
  }

  function onClearConversation(){
    handleClearConversation(selectedContent);
  }

  function handleSaveFromReminder() {
    if (tabSection.id === 2) {
      onSaveTranscript();
      setEditable(false);
      setIsUserUpdating(false);
    } else if (tabSection.id === 3) {
      onSaveRecap();
      setEditableRecap(false);
      setIsUserUpdatingRecap(false);
    }
    setSaveReminder(false);
  }

  function handleDontSave() {
    setSaveReminder(false);
    if (tabSection.id === 2) {
      setEditable(false);
      setIsUserUpdating(false);
    } else if (tabSection.id === 3) {
      setEditableRecap(false);
      setIsUserUpdatingRecap(false);
    }
  }

  function getChatIcon() {
    if (location.pathname.includes("meeting-manager")) {
      return meetingManager
    } else {
      return hrManager
    }
  }

  function handleInputConversation(e){
    e.target.style.height = 0;
    setInputConversation(e.target.value);
    e.target.style.height = e.target.scrollHeight+'px';
  }

  useEffect(() => {
    setInputConversation("");
    
    if (textarea.current !== null) {
      textarea.current.height = '48px';
    }
    
  }, [selectedContent])

  return (
    <>
      {selectedContent !== null && (
        <div className="content__tab">
          {/* tab menu mobile */}
          <div className="tab__menu-mobile">
            <img
              className="close-tab"
              src={close}
              alt="close"
              onClick={() => onClose()}
            />
            {contentMenu.map((item, index) => {
              return (
                <div
                  key={index}
                  className="tab__button"
                  style={{
                    border: tabSection.id === item.id ? "1px solid black" : "",
                    background:
                      tabSection.id === item.id ? "rgba(23, 25, 31, 0.05)" : "",
                  }}
                  onClick={() => handleClickOnMenu(item)}
                >
                  <img src={item.img} alt={item.name}></img>
                </div>
              );
            })}
            {/* share button mobile */}
            <div className="share__button">
              <img src={download} alt="Share" onClick={onContentDownload}></img>
            </div>
          </div>
          <div className="tab__body">
            {/* tab content */}
            {/* Prompt */}
            {tabSection.id === 1 && (
              <div className="tab-body__container">
                <h3>AI copilot chat: {selectedContent.title.length > 70 ? selectedContent.title.substring(0, 70) + "..." : selectedContent.title}</h3>
                <div className="tab__content-conversation">
                  <div className="conversation-items">
                    {/* currentConversation */}
                    {currentConversation &&
                      currentConversation.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            {item.q !== null ? item.hasOwnProperty("q") && (
                              <div className="conversation__item">
                                <img src={avatar} alt="avatar"></img>
                                <p>{item.q}</p>
                              </div>
                            ): ""}
                            {item.a !== null ? item.hasOwnProperty("a") && (
                              <div className="conversation__item">
                                <img src={getChatIcon()} alt="avatar"></img>
                                <p dangerouslySetInnerHTML={{ __html: item.a }}></p>
                              </div>
                            ): ""}
                          </Fragment>
                        );
                      })}
                    {isLoading && <ChatLoader />}
                  </div>
                </div>
                {/* conversation input */}
                <form className="conversation__form">
                    {/* old dynamic textarea */}
                    {/* <textarea
                      ref={textarea}
                      className="conversation__input"
                      value={inputConversation}
                      rows={1}
                      onChange={(e) => handleInputConversation(e)}
                      disabled={selectedContent.status !== 'complete'}
                    /> */}
                    <div className="conversation__clear" onClick={onClearConversation}><img src={clear} alt="Clear conversation"></img></div>
                    <input
                    className="conversation__input"
                    value={inputConversation}
                    onChange={(e) => setInputConversation(e.target.value)}
                  />
                    {/* <div className="conversation__credits">
                      <img src={credit} alt="credits"></img> 1
                    </div> */}
                    <button
                    className="conversation__submit"
                    onClick={(e) => onSendConversation(e)}
                    disabled={selectedContent.status !== 'complete'}
                  >
                    <img src={send} alt="send"></img>
                  </button>
                  
                </form>
              </div>
            )}
            {/* Context */}
            {tabSection.id === 2 && (
              <div className="tab-body__container">
                <h3>AI extracted context: {selectedContent.title.length > 70 ? selectedContent.title.substring(0, 70) + "..." : selectedContent.title}</h3>
                <div
                  className="tab__content"
                  // style={{ overflowY: editable ? "hidden" : "scroll" }}
                  onClick={makeTranscriptEditable}
                >
                  {/* {!editable && (
                    <p>{selectedContent && selectedContent.transcript}</p>
                  )} */}
                  
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        if (
                          editable &&
                          updatedTranscript !== selectedContent.transcript
                        ) {
                          setSaveReminder(true);
                        } else {
                          setEditable(false);
                          setIsUserUpdating(false);
                        }
                      }}
                    >
                      <EditableArea
                        text={selectedContent.transcript}
                        handleUpdatedText={handleUpdatedTranscript}
                        editable={editable}
                      />
                    </OutsideClickHandler>
                  
                </div>
                <div className="recap__title-flex">
                    <p>
                      Last saved on {formatDate(selectedContent.updateDate)}
                    </p>
                    {/* <div className="recap__credits">
                      <img src={credit} alt="credits"></img>5
                    </div>
                    <button>Update</button> */}
                  </div>
                {/* context button */}
                {isUserUpdating && (
                  <div className="flex__end">
                    <button
                      className="black__button"
                      onClick={onSaveTranscript}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            )}
            {/* Recap */}
            {tabSection.id === 3 && (
              <div className="tab-body__container">
                
                <div className="recap__title">
                {/* <div className="title__tooltip">{selectedContent.title}</div> */}
                  <h3>AI generated recap: {selectedContent.title.length > 70 ? selectedContent.title.substring(0, 70) + "..." : selectedContent.title}</h3>
                </div>
                <div
                  className="tab__content"
                  onClick={makeRecapEditable}
                  //style={{ overflowY: editableRecap ? "hidden" : "scroll" }}
                >
                  <div>
                    {/* {!editableRecap && (
                      <p>{selectedContent && selectedContent.recap}</p>
                    )} */}
                    
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          if (
                            editableRecap &&
                            updatedRecap !== selectedContent.recap
                          ) {
                            setSaveReminder(true);
                          } else {
                            setEditableRecap(false);
                            setIsUserUpdatingRecap(false);
                          }
                        }}
                      >
                       <EditableArea
                        text={selectedContent.recap}
                        handleUpdatedText={handleUpdatedRecap}
                        editable={editableRecap}
                      />
                      </OutsideClickHandler>
                  </div>
                  
                </div>
                <div className="recap__title-flex">
                    <p>
                      Last saved on {formatDate(selectedContent.updateDate)}
                    </p>
                    {/* <div className="recap__credits">
                      <img src={credit} alt="credits"></img>5
                    </div>
                    <button>Update</button> */}
                  </div>
                {/* context button */}
                {isUserUpdatingRecap && (
                  <div className="flex__end">
                    <button className="black__button" onClick={onSaveRecap}>
                      Save
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* tab menu desktop */}
          <div className="tab__menu">
            
            <div className="tab-menu__flex">
            <img
              className="close-tab"
              src={close}
              alt="close"
              onClick={() => onClose()}
            />
              <div className="tab-menu__buttons">
                {contentMenu.map((item, index) => {
                  return (
                    <div className="tab-button__wrapper" key={index}>
                      <div className="tab-button__tag">{item.tag}</div>
                      <div
                        key={index}
                        className="tab__button"
                        style={{
                          border:
                            tabSection.id === item.id ? "1px solid black" : "",
                          background:
                            tabSection.id === item.id
                              ? "rgba(23, 25, 31, 0.05)"
                              : "",
                        }}
                        onClick={() => handleClickOnMenu(item)}
                      >
                        <img src={item.img} alt={item.name}></img>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="tab__share">
                <div className="tab-button__tag-share">Download</div>
                <div className="share__button" onClick={onContentDownload}>
                  <img src={download} alt="Download"></img>
                </div>
              </div>
            </div>
          </div>
          {/* save reminder modal */}
          {saveReminder && (
            <div className="overlay">
              <div className="delete__modal">
                <div className="delete__modal-header">
                  Wait!
                  <img
                    src={close}
                    alt="close"
                    onClick={() => setSaveReminder(false)}
                  ></img>
                </div>
                <div className="delete__modal-body reminder-body">
                  <p>Your changes will not be saved.</p>
                  <div className="delete__buttons-container">
                    <button className="cancel-button" onClick={handleDontSave}>
                      I don't care
                    </button>
                    <button
                      className="save-button"
                      onClick={handleSaveFromReminder}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
