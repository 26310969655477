import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import purple from '../assets/vectors/purple.svg';
import blue from '../assets/vectors/blue.svg';
import green from '../assets/vectors/green.svg';
import { Link } from "react-router-dom";

export default function StripeFailure() {

  return (
    <>
      <Navbar />
      <div className="login__container">
        <img className="purple" src={purple} alt="color"></img>
        <img className="green" src={green} alt="color"></img>
        <img className="blue" src={blue} alt="color"></img>
        <h1>Something went wrong with the payment!</h1>
        <Link to="/pricing-plans">
        <button className="black__button">Back to pricing plans</button>
        </Link>
      </div>
      <Footer />
    </>
  );
}
