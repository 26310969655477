export const BASE_URL = "http://localhost:3000"; //10097  3000
// https://ba0492e8-09fc-40be-bd24-7e0e274f2394.mock.pstmn.io
// variante
//export const PROD_URL = "https://www.recapgpt.com/api";

export const METHODS = {
  POST: "POST",
  GET: "GET",
  PATCH: "PATCH",
  DELETE: "DELETE",
  PUT: "PUT"
}

export const ENDPOINT = {
  LOGIN: "login",
  ME: "me",
  USER: "user",
  CONTENT: "content",
  TRASHED: "trashed",
  QUESTION : "question",
  CREDITS: "credits",
  TRANSACTIONS: "transactions",
  RESETPASSWORD: "reset-password",
  REQUESTRESETPASSWORD: 'request-reset-password',
  REGISTER: "register"
}



export const publicFetch = async (URL, method, body = null) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const res = await fetch(URL, options);
    let result = null;
    if (res.headers.get("content-type") === "application/json") {
      result = await res.json();
    } else if (res.headers.get("content-type") === "text/json") {
      result = await res.text();
    }
    if (res?.ok) {
      return result;
    } else {
      throw new Error(result.message ? result.message : result);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const protectedFetch = async (URL, method, body = null, responseType = null) => {
  const auth = JSON.parse(localStorage.getItem('gpt-auth'));

  if (auth && auth.accessToken) {
    const accessToken = auth.accessToken;

    try {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method,
      }

      if (body) {
        options.body = JSON.stringify(body);
      }

      if (responseType) {
        options.headers['Response-Type'] = responseType;
      }

      const res = await fetch(URL, options);
      const result = await res.json();
      if (res?.ok) {
        return result;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      throw new Error(error);
    }
  } else {
    return "Not authenticated.";
  }
}

export const protectedFetchFile = async (URL, method, body = null, responseType = null) => {
  const auth = JSON.parse(localStorage.getItem('gpt-auth'));

  if (auth && auth.accessToken) {
    const accessToken = auth.accessToken;

    try {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        method,
      }

      if (body) {
        options.body = JSON.stringify(body);
      }

      if (responseType) {
        options.headers['Response-Type'] = responseType;
      }

      const res = await fetch(URL, options);
      if (res.ok) {
        return await res.blob();
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      throw new Error(error);
    }
  } else {
    return "Not authenticated.";
  }
}
