import { METHODS, ENDPOINT, protectedFetch } from "./general";
import { PROD_URL } from '../config/config_local';


//PUT SINGLE CONVERSATION
export async function addContentInteraction(id, question) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.QUESTION}/${id}`,
    METHODS.PUT,
    {
      question: question,
    }
  );
  return response;
}

//CLEAR CONVERSATION

export async function cancelConversation(id) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.QUESTION}/${id}/clear`,
    METHODS.PUT,
  );
  return response;
}
