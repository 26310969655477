import { useState, useEffect, Fragment } from "react";
import Layout from "../components/Layout";
import { restoreContent, deleteContent, getContentByType } from "../api/content";
import ConversationPreview from "../components/ConversationPreview";
import down from '../assets/vectors/down.svg';
import trashIcon from "../assets/vectors/delete.svg";
import restore from "../assets/vectors/restore.svg";
import close from "../assets/vectors/close.svg";

//Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Trash() {
  const [contents, setContents] = useState([]);
  const [elementsIds, setElementsIds] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [openActionsDropdown, setOpenActionsDropdown] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  

  const type = "trashed";

  // getting all contents
  async function getData() {
    try{
      const data = await getContentByType(type)
      const filteredContents = data.filter(content => {
        return content.isInTrash === true
      })
      setContents(filteredContents)
    }catch(error){
      console.log(error)
    }
    // setContents(data);
  }

  //restoring contents
  async function restoreContents(){
    setLoading(true);
    if (elementsIds.length !== 0){
      for(let i = 0; i < elementsIds.length; i++) {
        const data = await restoreContent(elementsIds[i]);
      } 
    toast.success('File restored')
    getData()
    setLoading(false);
    setElementsIds([]) 
    }
    else{
      toast.error('Please select at least one item to restore')
      setLoading(false)
    }
    //closing dropdown after restore
    setOpenActionsDropdown(false)
  }

  //deleting contents 
  async function deleteContents(){
    setLoading(true);
    if(elementsIds.length !== 0){
      for(let i = 0; i < elementsIds.length; i++) {
        const data = await deleteContent(elementsIds[i]);
      }  
    toast.success('File deleted')
    getData()
    setLoading(false);
    setElementsIds([])
    }else {
      toast.error('Please select at least one item to delete')
      setLoading(false)
    }
    
    //closing dropdown and modal after delete
    setOpenActionsDropdown(false)
    setOpenDeleteModal(false)
  }

  useEffect(() => {
    getData(type);
  }, []);

  //getting elements ID in order to delete/restore
  function handleElementsId(id, operation) {
    let newElements = [];
    if (operation === "add") {
      newElements = [...elementsIds, id];
    } else {
      newElements = elementsIds.filter(elem => elem !== id);
    }
    setElementsIds(newElements);
  }

  //handling delete modal

  function handleDeleteModal(){
    setOpenDeleteModal(true);
    setOpenActionsDropdown(false)
  }

  return (
    <>
      <Layout>
        {
          isLoading && <p>Loading</p>
        }
        <div className="dashboard__container">
          <h3>Trash</h3>
          <p>
          Welcome to the Trash section! This is where you can find all the jobs that you have deleted from your account. You can restore any item in the Trash section by simply clicking on the "Restore" button under "Actions" menu. If you want delete them immediately, just select the item and click on the "Delete" button under "Actions" menu.
          </p>
          <div className="trash__actions">
            {contents.length !== 0 && <button onClick={()=> setOpenActionsDropdown(!openActionsDropdown)}>Actions <img src={down} alt="see actions"></img></button>}
            {openActionsDropdown && <div className="actions__dropdown">
              <ul>
                <li onClick={restoreContents}>
                  <img src={restore} alt="Restore"></img>
                  <span>Restore</span>
                </li>
                <li onClick={handleDeleteModal}>
                  <img src={trashIcon} alt="Delete"></img>
                  <span>Delete</span>
                </li>
              </ul>
            </div>}
          </div>
          
          {/* desktop */}
          <div className="conversation__wrapper desktop">
            {contents &&
              contents.map((item) => {
                return (
                  // card
                  <Fragment key={item.id}>
                    <ConversationPreview
                      content={item}
                      type={type}
                      handleCheckbox={handleElementsId}
                    />
                  </Fragment>
                  // card
                );
              })}
          </div>
          {/* mobile */}
          <div className="conversation__wrapper mobile">
            
              {contents &&
                contents.map((item) => {
                  return (
                      <ConversationPreview content={item} key={item.id} type={type} handleCheckbox={handleElementsId} />
                  );
                })}
          
          </div>

          {/* overlay */}
          {openDeleteModal && <div className="overlay">
            <div className="delete__modal">
              <div className="delete__modal-header">
                Delete
                <img
                src={close}
                alt="close"
                onClick={()=> setOpenDeleteModal(false)}
              ></img>
              </div>
              <div className="delete__modal-body">
                <p>Are you sure you want to permanently delete the selected documents? This action cannot be undone.</p>
                <div className="delete__buttons-container">
                  <button className="cancel-button" onClick={()=> setOpenDeleteModal(false)}>Cancel</button>
                  <button className="delete-button" onClick={deleteContents}>Delete</button>
                </div>
              </div>
            </div>
          </div>}
          {/* overlay */}
        </div>

        {/* TOAST */}
        
      </Layout>
    </>
  );
}
