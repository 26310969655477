import React from "react";
import Sidebar from "../Sidebar";

export default function Layout({ children }) {
  return (
    <div className="recap__wrapper">
      <Sidebar />
      <div className="recap__container">{children}</div>
    </div>
  );
}
