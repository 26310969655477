import React, { useState, useEffect } from "react";
import dots from "../../assets/vectors/dots.svg";
import FilePdf from "../../assets/vectors/FilePdf.svg";
import FileTxt from "../../assets/vectors/txt.svg";
import FileRtf from "../../assets/vectors/rtf.svg";
import FileDoc from "../../assets/vectors/doc.svg";
import FileDocx from "../../assets/vectors/docx.svg";
import FileAvi from "../../assets/vectors/avi.svg";
import FileMkv from "../../assets/vectors/mkv.svg";
import FileMp3 from "../../assets/vectors/mp3.svg";
import FileMp4 from "../../assets/vectors/mp4.svg";
import FileWav from "../../assets/vectors/wav.svg";
import trashIcon from "../../assets/vectors/delete.svg";
import info from "../../assets/vectors/info.svg";
import close from "../../assets/vectors/close.svg";
import warning from "../../assets/vectors/warning.svg";
import { formatDate, isDateInThisWeek } from "../../utils/dates";
import { TailSpin } from "svg-loaders-react";
import { TagsInput } from "react-tag-input-component";
//Toastify
import "react-toastify/dist/ReactToastify.css";

export default function ConversationPreview({
  content,
  handleCheckbox,
  handleDelete,
  handleEdit,
  onContextMenuClick,
  isActive,
  isTabOpen,
  handleCloseContentTab,
  handleDropdownClick,
  showDropdown,
  anchor,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [editable, setEditable] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(content.title);
  const [updatedTags, setUpdatedTags] = useState(content.tags);
  
  //cloding detail modal + making disappear dropdown
  function handleCloseModal() {
    setOpenModal(false);
  }

  //handling checkbox when in trash
  function handleChange() {
    let newChecked = !checked;
    setChecked(newChecked);
    if (newChecked) {
      handleCheckbox(content.id, "add");
    } else {
      handleCheckbox(content.id, "remove");
    }
  }

  //handling title field
  function makeTitleEditable() {
    setEditable(true);
  }

  //handling title update
  function handleContentDataUpdate() {
    handleEdit(content.id, updatedTitle, updatedTags);
    setOpenModal(false);
  }

  function handleDropdownOpen(event) {
    event.stopPropagation();
    handleDropdownClick();
  }

  function handleOpenModal(event) {
    event.stopPropagation();
    setOpenModal(true);
    handleCloseContentTab();
  }

  useEffect(() => {
    isDateInThisWeek(content.uploadDate);
  }, [content]);

  function deleteContent(event, id) {
    event.stopPropagation();
    handleDelete(id);
  }

  function getClassname() {
    if (isActive) {
      return "conversation__card-wrapper active";
    } else {
      return "conversation__card-wrapper";
    }
  }

  function returnContentIcon() {
    if (content.originalFileFormat === "pdf") {
      return FilePdf;
    } else if (content.originalFileFormat === "txt") {
      return FileTxt;
    } else if (content.originalFileFormat === "rtf") {
      return FileRtf;
    } else if (content.originalFileFormat === "doc") {
      return FileDoc;
    } else if (content.originalFileFormat === "docx") {
      return FileDocx;
    }
  }

  function returnAudioIcon() {
    if (content.originalFileFormat === "mp4") {
      return FileMp4;
    } else if (content.originalFileFormat === "mp3") {
      return FileMp3;
    } else if (content.originalFileFormat === "avi") {
      return FileAvi;
    } else if (content.originalFileFormat === "mkv") {
      return FileMkv;
    } else if (content.originalFileFormat === "wav") {
      return FileWav;
    }
  }

  function getContentFrame() {
    if (content.contentType === "video") {
      // VIDEO
      if (content.status === "complete") {
        if (content.previewImage !== null) {
          return (
            <img
              className="conversation__frame"
              src={content.previewImage}
              alt="video frame"
            ></img>
          );
        } else {
          return (
            <div className="conversation__frame">
              <img
                className="pdf_icon"
                src={returnAudioIcon()}
                alt="File"
              ></img>
            </div>
          );
        }
      } else if (content.status === "error") {
        return (
          <div className="loading__frame loading__frame-error">
            <img src={warning} alt="Processing error"></img>
          </div>
        );
      } else {
        // TODO: loader
        return (
          <div className="loading__frame">
            <TailSpin />
          </div>
        );
      }
    } else if (content.contentType === "pdf") {
      // PDF
      if (content.status === "complete") {
        //complete

        return (
          <div className="conversation__frame">
            <img
              className="pdf_icon"
              src={returnContentIcon()}
              alt="File"
            ></img>
          </div>
        );
      } else if (content.status === "error") {
        //error
        return (
          <div className="loading__frame loading__frame-error">
            <img src={warning} alt="Processing error"></img>
          </div>
        );
      } else {
        // TODO: loader
        return (
          <div className="loading__frame">
            <TailSpin />
          </div>
        );
      }
    }
  }

  function getStatusMessage() {
    if (content.status === "init") {
      return "Elaboration started.";
    } else if (content.status === "awaiting_transcript") {
      return "Generating context.";
    } else if (content.status === "awaiting_summary") {
      return "Generating recap.";
    } else if (content.status === "error") {
      return "Processing error";
    } else {
      return "";
    }
  }

  return (
    <>
      {/* CARD */}
      <div className="card" id={anchor}>
        <div className={getClassname()}></div>
        <div
          className="conversation__card"
          key={content.id}
          onClick={() => onContextMenuClick(content)}
        >
          {/* dropdown icon (only visible if item is not in trash) */}
          {!content.isInTrash && (
            <div
              className="conversation__detail"
              onClick={(event) => {
                handleDropdownOpen(event);
              }}
            >
              <img src={dots} alt="conversation details"></img>
            </div>
          )}
          {/* checkbox (only visible if item is in trash) */}
          {content.isInTrash && (
            <input
              type="checkbox"
              className="trash__checkbox"
              checked={checked}
              onChange={handleChange}
            ></input>
          )}
          {/* frame / placeholder */}
          {getContentFrame()}
          {/* dropdown */}
          {showDropdown && (
            <div className="conversation__dropdown">
              <ul>
                <li onClick={handleOpenModal}>
                  <img src={info} alt="Info" loading="eager"></img>
                  <span>Info</span>
                </li>
                <li onClick={(event) => deleteContent(event, content.id)}>
                  <img src={trashIcon} alt="Delete" loading="eager"></img>
                  <span>Delete</span>
                </li>
              </ul>
            </div>
          )}
          {/* dropdown */}
          <div className="conversation__details">
            {/* <span>
              {isDateToday(new Date(content.uploadDate)) === true
                ? content.processTime
                : formatDate(content.uploadDate)}
            </span> */}
            <span>{formatDate(content.uploadDate)}</span>
            <h5>
              {content.title.length > 30
                ? content.title.substring(0, 30) + "..."
                : content.title}
            </h5>
            <span className="status__message">{getStatusMessage()}</span>
          </div>
        </div>
      </div>

      {/* DETAIL MODAL */}
      {openModal && (
        <div className="overlay">
          <div className="conversation__modal">
            <div className="modal__header">
              Info
              <img
                src={close}
                alt="close"
                onClick={() => handleCloseModal()}
              ></img>
            </div>
            <div className="modal__body">
              <div className="modal__row">
                <span>ID</span>
                {content.id ? content.id : "N/A"}
              </div>
              <div className="modal__row">
                <span>Title</span>
                {!editable && (
                  <p
                    className="content__title"
                    contentEditable={editable}
                    onClick={makeTitleEditable}
                  >
                    {updatedTitle}
                  </p>
                )}
                {editable && (
                  <textarea
                    value={updatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                  ></textarea>
                )}
              </div>
              <div className="modal__row">
                <span>Original file name</span>
                {content.originalFileName ? content.originalFileName : "N/A"}
              </div>
              <div className="modal__row">
                <span>Original file format</span>
                {content.originalFileFormat
                  ? content.originalFileFormat
                  : "N/A"}
              </div>
              <div className="modal__row">
                <span>Original file size</span>
                {content.originalFileSize ? content.originalFileSize : "N/A"}
              </div>
              <div className="modal__row capital">
                <span>Original language</span>
                {content.originalLanguage ? content.originalLanguage : "N/A"}
              </div>
              <div className="modal__row capital">
                <span>Target language</span>
                {content.targetLanguage ? content.targetLanguage : "N/A"}
              </div>
              <div className="modal__row">
                <span>Upload date & time</span>
                {content.uploadDate ? formatDate(content.uploadDate) : "N/A"}
              </div>

              <div className="modal__row">
                <span>Tags</span>
                {/* {!editableTags && (
                  <p
                    className="content__title"
                    contentEditable={updatedTags}
                    onClick={makeTagsEditable}
                  >
                    {updatedTags.length > 0 ? updatedTags.join(", ") : 'N/A' }
                  </p>
                )}
                {editableTags && (
                  <textarea
                    value={updatedTags}
                    onChange={(e) => setUpdatedTags(e.target.value)}
                  ></textarea>
                )} */}
                <TagsInput
                  value={updatedTags}
                  onChange={setUpdatedTags}
                  name="Tags"
                  placeHolder="Enter tags"
                  separators={["Enter", " "]}
                />
              </div>

              <button
                className="modal__button"
                onClick={() =>
                  handleContentDataUpdate(content.id, updatedTitle, updatedTags)
                }
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
