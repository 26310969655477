import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import purple from '../assets/vectors/purple.svg';
import blue from '../assets/vectors/blue.svg';
import green from '../assets/vectors/green.svg';


export default function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="login__container">
        <img className="purple" src={purple} alt="color"></img>
        <img className="green" src={green} alt="color"></img>
        <img className="blue" src={blue} alt="color"></img>
        <div className="not-found__flex">
            <h1>404</h1>
            <h2>Page not found</h2>
            <div onClick={() => navigate(-1)} className="black__button">
              Go back
            </div>
          </div>
      </div>
      <Footer />
    </>
  );
}
