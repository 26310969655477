import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import Navbar from "../components/Navbar";
import { AuthContext } from "../context/AuthContext";
import purple from '../assets/vectors/purple.svg';
import blue from '../assets/vectors/blue.svg';
import green from '../assets/vectors/green.svg';
import RegisterForm from "../components/RegisterForm";

export default function Register() {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("gpt-auth"));

    if (auth) {
      const currentTimeStamp = Math.floor(new Date().getTime() / 1000);
      const tokenObj = JSON.parse(window.atob(auth.accessToken.split(".")[1]));
      const tokenIsNotExpired = tokenObj.exp > currentTimeStamp;
      if (tokenIsNotExpired) {
        setAuthState(auth);
        navigate("/search");
      }
    }
  }, [authState]);

  return (
    <>
      <Navbar />
      <div className="login__container">
        <img className="purple" src={purple} alt="color"></img>
        <img className="green" src={green} alt="color"></img>
        <img className="blue" src={blue} alt="color"></img>
        <h1>Register</h1>
        <RegisterForm />
      </div>
      <Footer />
    </>
  );
}
