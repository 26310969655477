import { useState } from "react";
import Layout from "../components/Layout";
import feature from "../assets/vectors/feature.svg";

export default function PricingPlans() {
  const transactionsPerSection = 10;
  const [next, setNext] = useState(transactionsPerSection);

  function handleMoreTransactions() {
    setNext(next + transactionsPerSection);
  }


  //Stripe checkout Urls
  const STRIPE_STARTER_URL = "https://buy.stripe.com/test_14k3eq2FicXY47e5kk";
  const STRIPE_PRO_URL = "https://buy.stripe.com/test_14k3eq2FicXY47e5kk";
  const STRIPE_ENTERPRISE_URL = "https://buy.stripe.com/test_14k3eq2FicXY47e5kk";

  return (
    <>
      <Layout>
        <div className="dashboard__container">
          <h3>Pricing Plans</h3>
          {/* card */}

          <div className="plans__wrapper">
            <div className="plans__container">
              {/* Hobby */}
              <div className="pricing__card">
                <h5>Hobby</h5>
                <ul>
                  <li>
                    <img src={feature} alt="feature"></img> 10 prompts/month
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> 10 minutes/month
                    audio/video upload{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> 3 docs/month upload{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> GPT 3.5{" "}
                  </li>
                </ul>
                <div className="plan__details">
                  <h6>Free</h6>
                  {/* <button className="black__button">Select plan</button> */}
                </div>
              </div>
              {/* Starter */}
              <div className="pricing__card">
                <h5>Starter</h5>
                <ul>
                  <li>
                    <img src={feature} alt="feature"></img> 750 credits for
                    pay-as-you go service
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> unlimited doc, audio
                    and video uploads{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> Access to basic
                    templates{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> GPT 3.5{" "}
                  </li>
                </ul>
                <div className="plan__details">
                  <h6>
                    $10 <span>/Month</span>
                  </h6>
                  <button onClick={() => window.location.href = STRIPE_STARTER_URL} className="black__button">Select plan</button>
                </div>
              </div>
              {/* Pro */}
              <div className="pricing__card">
                <div className="popular__tag">Popular</div>
                <h5>Pro</h5>
                <ul>
                  <li>
                    <img src={feature} alt="feature"></img> 5000 credits- for
                    pay-as-you go service
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> unlimited doc, audio
                    and video uploads{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> Access to premium
                    templates{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> Access to fine tuned
                    GPT{" "}
                  </li>
                </ul>
                <div className="plan__details">
                  <h6>
                    $50 <span>/Month</span>
                  </h6>
                  <button onClick={() => window.location.href = STRIPE_PRO_URL} className="black__button">Select plan</button>
                </div>
              </div>
              {/* Enterprise */}
              <div className="pricing__card">
                <h5>Enterprise</h5>
                <ul>
                  <li>
                    <img src={feature} alt="feature"></img> unlimited access to
                    content
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> unlimited doc, audio
                    and video uploads{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> bespoke templates{" "}
                  </li>
                  <li>
                    <img src={feature} alt="feature"></img> bespoke fine tuned
                    GPT and integrations{" "}
                  </li>
                </ul>
                <div className="plan__details">
                  <h6>Contact</h6>
                  <button onClick={() => window.location.href = STRIPE_ENTERPRISE_URL} className="black__button">Select plan</button>
                </div>
              </div>
            </div>
          </div>
          {/* FAQ */}
          <div className="faq__wrapper"></div>
          <h3>Pricing FAQs</h3>
          <div className="faq__container">
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
            <div className="single__faq">
              <h4>Question</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Bibendum felis tristique
                proin bibendum egestas tortor fermentum sapien. Feugiat dictumst
                neque iaculis quis vehicula cursus neque. Nullam dignissim
                pharetra pharetra.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
