import React, { useContext } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCredits, getUserData, login, passwordReset } from "../../api/user";
import { useState } from "react";
import eyeVector from "../../assets/vectors/eye.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

//validation
const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});


const setPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_2: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  tos: Yup.boolean().oneOf([true], 'Field must be checked'),
});

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  function setPasswordVisibility() {
    setShowPassword(!showPassword);
  }

  async function onSubmit(values) {
    try {
      const res = await login(values);
      if (res.access_token) {
        localStorage.setItem(
          "gpt-auth",
          JSON.stringify({
            accessToken: res.access_token,
            isLoggedIn: true,
          })
        );
        const user = await getUserData();
        const credits = await getCredits();
        const newAuthState = {
          ...authState,
          accessToken: res.access_token,
          isLoggedIn: true,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          userId: user.userId,
          avatar: user.avatar,
          country: user.country,
          address: user.address,
          city: user.city,
          zipcode: user.zipcode,
          currentCredits: credits.currentCredits,
          videoModal: user.displayIntro,
        };
        setAuthState(newAuthState);
        localStorage.setItem("gpt-auth", JSON.stringify(newAuthState));
        navigate("/search");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="login__form">
      {/* Formik */}
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Form>
                <label htmlFor="username">Email address</label>
                <Field
                  type="text"
                  name="username"
                  label="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  placeholder="Username"
                  className={
                    errors.username && touched.username ? "field__error" : ""
                  }
                />
                {errors.username && touched.username ? (
                  <span className="form__error">{errors.username}</span>
                ) : null}

                <label htmlFor="username">Your password</label>
                <div className="psw__field">
                  <Field
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    className={
                      errors.username && touched.username
                        ? "field__error psw__field"
                        : "psw__field"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Password"
                  />
                  <img
                    className="eye__vector"
                    src={eyeVector}
                    onClick={setPasswordVisibility}
                    alt="See password"
                  ></img>
                </div>
                {errors.password && touched.password ? (
                  <span className="form__error">{errors.password}</span>
                ) : null}

                <button type="submit" onClick={handleSubmit}>
                  Sign in
                </button>
                <span className="forgot-psw__link" onClick={()=> navigate('/reset-password')}>
                Forgot your password?
              </span>
              </Form>
            </>
          )}
        </Formik>
    </div>
  );
}
