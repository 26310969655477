import React, { useContext } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCredits, getUserData, login, passwordReset } from "../../api/user";
import { useState } from "react";
import eyeVector from "../../assets/vectors/eye.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

//validation
const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});


const setPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_2: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  tos: Yup.boolean().oneOf([true], 'Field must be checked'),
});

export default function ResetPasswordForm({ userHash }) {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  function setPasswordVisibility() {
    setShowPassword(!showPassword);
  }

  async function onSubmit(values) {
    try {
      const res = await passwordReset(values); //set password POST here
      if (res.status === 200) {
          navigate('/')
      }
    } catch(error){
      toast.error(error.message)
    }
    
  }

  return (
    <div className="login__form">
      {/* Formik */}
        
        <Formik
          initialValues={{
            password: "",
            password_2: "",
            hash: userHash,
          }}
          validationSchema={setPasswordSchema}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <Form>
                <label htmlFor="password">Password*</label>
                <div className="psw__field">
                  <Field
                    name="password"
                    label="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    className={
                      errors.password && touched.password
                        ? "field__error psw__field"
                        : "psw__field"
                    }
                  />
                  <img
                    className="eye__vector"
                    src={eyeVector}
                    onClick={setPasswordVisibility}
                    alt="See password"
                  ></img>
                </div>
                {errors.password && touched.password ? (
                  <span className="form__error psw__error">
                    {errors.password}
                  </span>
                ) : null}

                {/* Repeat Password field */}
                <label htmlFor="password">Confirm password*</label>
                <div className="psw__field">
                  <Field
                    name="password_2"
                    label="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password_2}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    className={
                      errors.password_2 && touched.password_2
                        ? "field__error psw__field"
                        : "psw__field"
                    }
                  />
                  <img
                    className="eye__vector"
                    src={eyeVector}
                    onClick={setPasswordVisibility}
                    alt="See password"
                  ></img>
                </div>
                {errors.password_2 && touched.password_2 ? (
                  <span className="form__error psw__error">
                    {errors.password_2}
                  </span>
                ) : null}

               

                <button type="submit" onClick={handleSubmit}>Reset Password</button>
                {/*  */}
                {/* <a href="/" className="signin__notice" onClick={()=> {setResetPassword(false)}}>Already have an account? Sign in</a> */}
                {/* password reset notice - once email has been sent*/}
                {/* <span className="psw-reset__notice">Check your email for the password reset link</span> */}
              </Form>
            </>
          )}
        </Formik>
    </div>
  );
}
