import { useState, useEffect, Fragment, useCallback } from "react";
import Layout from "../components/Layout";
import {
  getContentByType,
  trashContent,
  updateData,
  updateTranscript,
  updateRecap,
  downloadContent,
} from "../api/content";
import { addContentInteraction, cancelConversation } from "../api/conversation";
import { getCredits } from "../api/user";
import { isDateInThisWeek } from "../utils/dates";
import ConversationPreview from "../components/ConversationPreview";

//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Uploader from "../components/Uploader";
import ContentTab from "../components/ContentTab";
import { useParams } from "react-router";
import { useNavigate } from "react-router";

const type = "video";




export default function Conversations() {
  let { contentId } = useParams();
  const navigate = useNavigate()
  const [contents, setContents] = useState([]);

  const recentContents = contents.filter((content) => {
    return isDateInThisWeek(content.uploadDate);
  });
  const previousContents = contents.filter((content) => {
    return !isDateInThisWeek(content.uploadDate);
  });
  const [prevContentId, setPrevContentId] = useState(undefined);
  const [selectedContent, setSelectedContent] = useState(null);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);


  async function getData(type) {
    try {
      const data = await getContentByType(type);
      if (Array.isArray(data)) {
        setContents(data);
        return data;
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    async function init() {
      const data = await getData(type);
      pollingContents();
    }

    init();
  }, []);

  useEffect(() => {
    if (contentId !== undefined && contents.length > 0) {
      
      setPrevContentId(contentId);
      const item = contents.find(elem => elem.id.toString() === contentId);
      if (item !== undefined) {
        // Cambio di contentId
        if (contentId !== prevContentId) {
          setSelectedContent(item);
          setCurrentConversation(item.conversation);
          scrollTo(item.id);
        } 
        // Polling conversazione
        if (item.conversation.length > selectedContent?.conversation.length) {
          setSelectedContent(item);
          setCurrentConversation(item.conversation);
          scrollTo(item.id);
        }
      }
    }
  }, [contentId, contents]);
  

  const pollingContents = useCallback(async () => {
    await getData(type);
    setIsPolling(true);

    const polling = setInterval(async () => {
      const data = await getData(type);
      // controllo che esista almeno un content in elaborazione
      const shouldPolling = data.filter(item => item.status !== "complete" && item.status !== "error").length > 0;
      // se tutti i content sono terminati, termino il polling
      if (shouldPolling === false) {
        setIsPolling(false);
        clearInterval(polling);
      }
    }, 15000); // 15 sec
  })

  //move content to trash
  async function moveContentToTrash(id) {
    try {
      await trashContent(id);
      toast.warning("Content moved to Trash",{
        toastId: 2
      });
      getData(type);
      closeContentTab()
    } catch (error) {
      toast.error(error);
    }
  }

  //edit title and tags
  async function editData(id, newTitle, newTags) {
    const arrayTags = newTags.length > 0 ? newTags : []
    try {
      await updateData(id, newTitle, arrayTags);
      toast.success("Data updated");
      await getData(type);
    } catch (error) {
      toast.error(error);
    }
  }

  //handling content tab opening
  function handleClick(item) {
    navigate(`/meetings/${item.id}`);
  }

  // // edit transcript
  async function editTranscript(item, newTranscript) {
    try {
      await updateTranscript(item.id, newTranscript);
      refreshTabContent(item);
      toast.success("Context updated");
    } catch (error) {
      toast.error(error);
    }
  }

  // //edit summary
  async function editRecap(item, newRecap) {
    try {
      await updateRecap(item.id, newRecap);
      refreshTabContent(item);
      toast.success("Recap updated");
    } catch (error) {
      toast.error(error);
    }
  }

  //send conversation
  async function sendConversation(item, inputConversation) {
    const question = inputConversation;
    setIsLoading(true);
    try {
      setCurrentConversation([...currentConversation, { q: question }]);
      const response = await addContentInteraction(item.id, question);
      setCurrentConversation(currentConversation.slice(-1));
      setCurrentConversation([...currentConversation, { ...response }]);
      await getCredits();
      setIsLoading(false);
      refreshTabContent(item);
    } catch (error) {
      toast.error(error);
    }
  }

  //clear conversation
  async function clearConversation(item){
    try {
      const response = await cancelConversation(item.id);
      setCurrentConversation([...currentConversation, { ...response }]);
      refreshTabContent(item)
      toast.success('Conversation has been cleared')
    } catch (error) {
      toast.error(error);
    }
  }

  function closeContentTab() {
    setSelectedContent(null);
    setPrevContentId(undefined);
    document.getElementById('dashboard').style.marginBottom = "28px";
    document.getElementById('dashboard').style.paddingBottom = "28px";
    navigate(`/meetings`);
  }

  async function refreshTabContent(item) {
    try {
      const newData = await getData(type);
      setSelectedContent(null);
      const findUpdatedItem = newData.find((el) => el.id === item.id);
      setSelectedContent(findUpdatedItem);
      setCurrentConversation(findUpdatedItem.conversation);
    } catch (error) {
      toast.error(error);
    }
  }

  //download document
  async function handleDownload(content, type){
    try{
      const response = await downloadContent(content.id, type);
      const name = `${content.title}_${type}.txt`;
      if (type === 'chatlog') {
        download(response, name, 'application/json');
      } else {
        download(response, name, 'application/octet-stream');
      }
    } catch(error){
      toast.error(error)
    }
  }

  function download(response, name, type) {
    const url = URL.createObjectURL(new Blob([response], { type: type }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  }

  function scrollTo(id){
    const section = document.getElementById(id);
    
    if (window.innerWidth <= 1025){
      document.getElementById('dashboard').style.paddingBottom = "65%";
    } else if (window.innerWidth <= 769){
      document.getElementById('dashboard').style.paddingBottom = "60%";
    } else {
      document.getElementById('dashboard').style.paddingBottom = "42%";
    }
    // TODO: convertire in ref
    if (section) {
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }

  const [showDropdown, setShowDropdown] = useState(false);
  const [clickedContent, setClickedContent] = useState(null);
  function handleDropdownClick(contentId) {
    const newShowDropdown = !showDropdown;
    if (contentId !== clickedContent) {
      setClickedContent(contentId);
      setShowDropdown(true);
      return;
    }
    if (!newShowDropdown) {
      setClickedContent(null);
    } else {
      setClickedContent(contentId);
    }
    setShowDropdown(newShowDropdown);
  }

  return (
    <>
      <Layout>
        <div className="dashboard__container" id="dashboard">
          {/* DESKTOP */}
          <div className="desktop">
            <h3>This week jobs</h3>
            <div className="conversation__wrapper">
              <Uploader title="Upload an audio or video file" fileTypes={[".mkv", ".mp3", ".avi", ".mp4", ".m4a", ".wav"]} onUploadComplete={pollingContents} />
              {recentContents.map((item) => {
                return (
                  // card
                  <Fragment key={"conv" + item.id}>
                    {!item.isInTrash && (
                      <ConversationPreview
                        content={item}
                        type={type}
                        handleDelete={moveContentToTrash}
                        handleEdit={editData}
                        onContextMenuClick={handleClick}
                        isActive={selectedContent?.id === item.id}
                        isTabOpen={selectedContent !== null}
                        handleCloseContentTab={closeContentTab}
                        handleDropdownClick={() => handleDropdownClick(item.id)}
                        showDropdown={showDropdown && clickedContent === item.id}
                        anchor={item.id}
                      />
                    )}
                  </Fragment>
                  // card
                );
              })}
            </div>
            {previousContents.length > 0 && (
              <>
                <h3>Previous weeks jobs</h3>
                <div className="conversation__wrapper desktop">
                  {previousContents.map((item) => {
                    return (
                      // card
                      <Fragment key={"conv" + item.id}>
                        {!item.isInTrash && (
                          <ConversationPreview
                            content={item}
                            type={type}
                            handleDelete={moveContentToTrash}
                            handleEdit={editData}
                            onContextMenuClick={handleClick}
                            isActive={selectedContent?.id === item.id}
                            isTabOpen={selectedContent}
                            handleCloseContentTab={closeContentTab}
                            handleDropdownClick={() => handleDropdownClick(item.id)}
                            showDropdown={showDropdown && clickedContent === item.id}
                            anchor={item.id}
                          />
                        )}
                      </Fragment>
                      // card
                    );
                  })}
                </div>
              </>
            )}
          </div>

          {/* MOBILE */}
          <div className="mobile">
            <div className="conversation-heading__flex">
              <h3>This week jobs</h3>
              {/* uploader mobile */}
              {/* <Uploader /> */}
              {/* uploader mobile */}
            </div>
            <div className="conversation__wrapper mobile">
              {recentContents.map((item) => {
                return (
                  // slider cards
                  <Fragment key={"conv" + item.id}>
                    {!item.isInTrash && (
                      <ConversationPreview
                      content={item}
                      type={type}
                      handleDelete={moveContentToTrash}
                      handleEdit={editData}
                      onContextMenuClick={handleClick}
                      isActive={selectedContent?.id === item.id}
                      isTabOpen={selectedContent !== null}
                      handleCloseContentTab={closeContentTab}
                      handleDropdownClick={() => handleDropdownClick(item.id)}
                      showDropdown={showDropdown && clickedContent === item.id}
                      anchor={item.id}
                      />
                    )}
                  </Fragment>
                  // slider card
                );
              })}
            </div>

            {previousContents.length > 0 && (
              <>
                <h3 className="previous-title__mobile">Previous weeks jobs</h3>
                <div className="conversation__wrapper mobile">
                  {previousContents.map((item) => {
                    return (
                      // slider cards

                      <Fragment key={"conv" + item.id}>
                        {!item.isInTrash && (
                          <ConversationPreview
                          content={item}
                          type={type}
                          handleDelete={moveContentToTrash}
                          handleEdit={editData}
                          onContextMenuClick={handleClick}
                          isActive={selectedContent?.id === item.id}
                          isTabOpen={selectedContent !== null}
                          handleCloseContentTab={closeContentTab}
                          handleDropdownClick={() => handleDropdownClick(item.id)}
                          showDropdown={showDropdown && clickedContent === item.id}
                          anchor={item.id}
                          />
                        )}
                      </Fragment>
                      // slider card
                    );
                  })}
                </div>
              </>
            )}
          </div>

          {/* Content tab */}
          <ContentTab
            selectedContent={selectedContent}
            handleClose={closeContentTab}
            handleSaveTranscript={editTranscript}
            handleSaveRecap={editRecap}
            handleDownloadContent={handleDownload}
            currentConversation={currentConversation}
            handleSendConversation={sendConversation}
            handleClearConversation={clearConversation}
            isLoading={isLoading}
          />
        </div>
        {/* TOAST */}
        
      </Layout>
    </>
  );
}
