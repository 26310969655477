import Instructions from "../components/Instructions";
import Layout from "../components/Layout";


export default function Dashboard() {

    return (
        <>
          <Layout>
          <div className="dashboard__container">
            <Instructions/>
          </div>
          </Layout>
        </>
        
    );
  }