import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export const ProtectedRoute = ({ redirectTo }) => {
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem('gpt-auth'));

    if(auth) {
      const currentTimeStamp = Math.floor(new Date().getTime() / 1000);
      const tokenObj = JSON.parse(window.atob(auth.accessToken.split(".")[1]));
      const tokenIsNotExpired = tokenObj.exp > currentTimeStamp;
      if (tokenIsNotExpired && auth.isLoggedIn) {
        setAuthState(auth);
      } else {
        navigate(redirectTo);
      }
    } else {
      navigate(redirectTo);
    }
  }, [])

  return (
    <Outlet />
  )
};