import { BASE_URL, METHODS, ENDPOINT, publicFetch, protectedFetch } from "./general";
import { PROD_URL } from '../config/config_local';


//authentication
export async function login({ username, password }) {
  try {
    const response = await publicFetch(
      `${PROD_URL}/${ENDPOINT.LOGIN}`,
      METHODS.POST,
      { username, password }
    );
    return response;
  } catch(error) {
    throw new Error(error.message);
  }
}

//resetPassword
export async function passwordReset({ password, hash, tos }) {
  try {
    const response = await publicFetch(
      `${PROD_URL}/${ENDPOINT.RESETPASSWORD}`,
      METHODS.POST,
      { password, hash, tos }
    );
    return response;
  } catch(error) {
    throw error;
  }
}

//request resetPassword
export async function requestPasswordReset({ email }) {
  try {
    const response = await publicFetch(
      `${PROD_URL}/${ENDPOINT.REQUESTRESETPASSWORD}`,
      METHODS.POST,
      { email }
    );
    return response;
  } catch(error) {
    throw error;
  }
}

//register

export async function userRegister({ email, password, tos }) {
  try {
    const response = await publicFetch(
      `${PROD_URL}/${ENDPOINT.REGISTER}`,
      METHODS.POST,
      { email, password, tos }
    );
    return response;
  } catch (error) {
    throw error;
  }
}




//GET USER
export async function getUser() {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.ME}`,
    METHODS.GET,
  );
  return response;
}

//GET USER DATA
export async function getUserData() {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.USER}`,
    METHODS.GET,
  );
  return response;
}

//UPDATE USER DATA
export async function updateUser(formData) {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.USER}`,
    METHODS.PATCH,
    {
      firstName: formData.firstName,
      lastName: formData.lastName,
      country: formData.country,
      address: formData.address,
      city: formData.city,
      zipcode: formData.zipcode
    }
  );
  return response;
}

//GET CREDITS
export async function getCredits() {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.CREDITS}`,
    METHODS.GET,
  );
  return response;
}

//GET TRANSACTIONS
export async function getTransactions() {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.TRANSACTIONS}`,
    METHODS.GET,
  );
  return response;
}

//PAY FOR SINGLE INTERACTION
export async function payInteraction(id, currentCredits) {
  const response = await protectedFetch(
    `${BASE_URL}/${ENDPOINT.USER}/${id}`,
    METHODS.PATCH,
    {
      credits: currentCredits - 1,
    }
  );
  return response;
}

//DON'T SHOW VIDEO 

export async function doNotShowVideo() {
  const response = await protectedFetch(
    `${PROD_URL}/${ENDPOINT.USER}`,
    METHODS.PATCH,
    {
      displayIntro: false,
    }
  );
  return response;
}
