import React from 'react';
import contrast from '../../assets/vectors/contrast.svg';
import flash from '../../assets/vectors/Flash.svg';
import limitations from '../../assets/vectors/Limitations.svg';

export default function Instructions() {
  return (
    <div className='instruction__wrapper'>
        <div className='instruction__container'>
          <h1>RecapGPT</h1>
          <div className='instruction__row'>
            <div className='instruction_col'>
              <img src={flash} alt="Examples"></img>
              <h3>Capabilities</h3>
              <div className='instruction__box'>
              <p>Can extract the context from several data sources (audio, video, pdfs...)</p>
              </div>
              <div className='instruction__box'>
                <p>Understands context and answers your questions</p>
              </div>
              <div className='instruction__box'>
                <p>Extracts key info according to predefined recap templates (e.g. business plan -> business model canvas)</p>
              </div>
            </div>
            <div className='instruction_col'>
              <img src={limitations} alt="Capabilities"></img>
              <h3>Limitations</h3>
              <div className='instruction__box'>
                <p>This is an experimental alpha version</p>
              </div>
              <div className='instruction__box'>
                <p>Responds to questions related to the extracted context only</p>
              </div>
              <div className='instruction__box'>
                <p>Question and answers are limited to contexts up to about 5000 words long</p>
              </div>
            </div>
            <div className='instruction_col'>
            <img src={contrast} alt="Limitations"></img>
            <h3>Examples</h3>
              <div className='instruction__box'>
                <p>Could you send me the minutes of the meeting of this conversation, please?</p>
              </div>
              <div className='instruction__box'>
                <p>Please create a business model canvas from this document?</p>
              </div>
              <div className='instruction__box'>
                <p>What is this document about?</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
