import React, { useState, useEffect, useMemo, useContext } from "react";
import languageList from "../../utils/languages";
import close from "../../assets/vectors/close.svg";
import plus from "../../assets/vectors/+.svg";
import select from "../../assets/vectors/select.svg";
import uploadPlus from "../../assets/vectors/uploadPlus.svg";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { AuthContext } from "../../context/AuthContext";
import { PROD_URL } from '../../config/config_local'

//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Uploader({ fileTypes, title, onUploadComplete }) {
  const [openUploader, setOpenUploader] = useState(false);
  const { authState, setAuthState } = useContext(AuthContext);
  const [form, setForm] = useState({
    title: "",
    langIn: "",
    langOut: "",
  });
  const [showClose, setShowClose] = useState(true);

  //handling open uploader
  function showUploader() {
    setOpenUploader(!openUploader);
    setShowClose(true);
    //reset Uppy whenever I close/open the uploader
    uppy.getState().files = {};
    uppy.getState().totalProgress = null;
    setForm({
      title: "",
      langIn: "",
      langOut: "",
    });
  }

  const uppy = useMemo(() => {
    const uploader = new Uppy({
      id: "uppy",
      restrictions: {
        allowedFileTypes: fileTypes,
        maxNumberOfFiles: 1,
        requiredMetaFields: ["title", "langIn", "langOut"],
      },
      allowMultipleUploads: true,
    });

    uploader.use(XHRUpload, {
      endpoint: PROD_URL,
      formData: true,
      fieldName: "requestFile",
      headers: {
        Authorization: "Bearer " + authState.accessToken,
      },
    });
    uploader.on("upload", () => {
      setShowClose(false);
    });
    uploader.on("cancel-all", () => {
      setShowClose(true);
    });
    uploader.on("complete", (result) => {
      if (result.successful) {
        toast.success("File uploaded");
        setOpenUploader(false);
        uploader.getState().files = {};
        uploader.getState().totalProgress = null;
        setForm({
          title: "",
          langIn: "",
          langOut: "",
        });
        onUploadComplete();
      } else {
        toast.error("Something went wrong");
      }
    });

    return uploader;
  }, [authState]);

  function handleChange(event) {
    const { name, value } = event.target;
    const newForm = {
      ...form,
      [name]: value,
    };
    setForm(newForm);
    uppy.setMeta(newForm);
  }

  return (
    <>
      <div className="upload__card desktop" onClick={showUploader}>
        <div className="upload__inner">
          <img src={plus} alt="Upload"></img>
          <span>Upload context</span>
        </div>
      </div>
      {/* mobile */}
      <div className="mobile">
        <button className="upload__button" onClick={showUploader}>
          <img src={uploadPlus} alt="upload"></img>Upload
        </button>
      </div>
      {/* upload modal */}
      {openUploader && (
        <div className="overlay">
          <div className="uploader__container">
            <div className="uploader__header">
              {title}
              {showClose && (
                <img src={close} alt="close" onClick={showUploader}></img>
              )}
            </div>
            <div className="uploader__body">
              {/* FORM */}
              <form id="myForm" name="myForm" action="none">
                <label htmlFor="title">Title*</label>
                <input
                  type="text"
                  name="title"
                  value={form.title}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <label htmlFor="langIn">Original language*:</label>
                <div className="select__wrapper">
                  <select
                    name="langIn"
                    value={form.langIn}
                    onChange={(e) => handleChange(e)}
                    required
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {languageList.map((language, index) => {
                      return (
                        <option key={index} value={language.code}>
                          {language.name}
                        </option>
                      );
                    })}
                  </select>
                  <img src={select} alt="Select original language"></img>
                </div>
                <label htmlFor="langOut">Target language*:</label>
                <div className="select__wrapper">
                  <select
                    name="langOut"
                    value={form.langOut}
                    onChange={(e) => handleChange(e)}
                    required
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {languageList.map((language, index) => {
                      return (
                        <option key={index} value={language.code}>
                          {language.name}
                        </option>
                      );
                    })}
                  </select>
                  <img src={select} alt="Select target language"></img>
                </div>
                <input name="uppyResult" type="hidden" />
              </form>

              <div className="Uppy">
                <Dashboard
                  uppy={uppy}
                  target=".Uppy"
                  inline={true}
                  height={200}
                  metaFields={[
                    { id: "title", name: "Title" },
                    {
                      id: "langIn",
                      name: "Original Language",
                    },
                    {
                      id: "langOut",
                      name: "Target Language",
                    },
                  ]}
                  note="Video files only"
                  maxNumberOfFiles={1}
                  debug={true}
                  disableThumbnailGenerator={true}
                />
                {/* <form
                  action="https://xhr-server.herokuapp.com/uplo"
                  method="post"
                >
                  <h5>
                    Uppy was not loaded — slow connection, unsupported browser,
                    weird JS error on a page — but the upload still works,
                    because HTML is cool like that
                  </h5>
                  {/* <input type="file" name="files[]" multiple="" /> */}
                {/* <button type="submit">Fallback Form Upload</button> */}
                {/* </form> */}
              </div>

              <p>Allowed file types: {fileTypes.join(", ")}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
