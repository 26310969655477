import moment from 'moment-timezone';

export function isDateToday (date) {  
  const now = new Date()

    return date.getDate() === now.getDate() &&
         date.getMonth() === now.getMonth() &&
         date.getFullYear() === now.getFullYear()
}

// dateString format: 2023/06/04 06:57 AM
export function isDateInThisWeek(dataString) {
  /*
  const formattedDate = dateString.split(' ').shift();
  const date = new Date(formattedDate);
  const todayObj = new Date("2023/06/03");
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDateOfWeek = new Date(todayObj.setDate(todayDate - todayDay + 1));
  firstDateOfWeek.setHours(0, 0, 0, 0);
  console.log({ todayDate, todayDay, firstDateOfWeek: firstDateOfWeek.toDateString() });
  
  // get last date of week
  const lastDateOfWeek = new Date(firstDateOfWeek);
  lastDateOfWeek.setDate(lastDateOfWeek.getDate() + 6);
  lastDateOfWeek.setHours(23, 59, 59, 0);

  // if date is equal or within the first and last dates of the week
  return date >= firstDateOfWeek && date <= lastDateOfWeek;
  */
  // Converti la stringa in un oggetto Data
  const data = new Date(dataString);
    
  // Ottieni la data corrente
  const dataCorrente = new Date();
  
  // Imposta la data corrente al primo giorno della settimana corrente (lunedì)
  const giornoCorrente = dataCorrente.getDay();
  const differenzaGiorni = giornoCorrente === 0 ? 6 : giornoCorrente - 1;
  dataCorrente.setDate(dataCorrente.getDate() - differenzaGiorni);
  dataCorrente.setHours(0, 0, 0, 0);
  
  // Ottieni la data del prossimo lunedì
  const prossimoLunedì = new Date(dataCorrente);
  prossimoLunedì.setDate(dataCorrente.getDate() + 7);
  
  // Verifica se la data è compresa tra il lunedì corrente e il prossimo lunedì
  if (data >= dataCorrente && data < prossimoLunedì) {
    return true; // La data è della settimana corrente
  } else {
    return false; // La data è precedente alla settimana corrente
  }
}

export function formatDate(dateString) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = moment.tz(dateString, timezone).format();
  const formattedDate = moment(date).format("ddd D MMM YYYY h:mm A"); 
  return formattedDate;
}

export function formatDateTransaction(dateString) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = moment.tz(dateString, timezone).format();
  const formattedDate = moment(date).format("DD/MM/YYYY h:mm A"); 
  return formattedDate;
}