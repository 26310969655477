
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext, AuthContextState } from './context/AuthContext';
import { useState } from 'react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword'
import Conversations from './pages/Conversations';
import Documents from './pages/Documents';
import Trash from './pages/Trash';
import NotFound from './pages/NotFound';
import MyProfile from './pages/MyProfile';
import Chat from './pages/Chat';
import { ProtectedRoute } from './components/ProtectedRoute';
import Credits from './pages/Credits';
import { ToastContainer } from 'react-toastify';
import PricingPlans from './pages/PricingPlans';
import Register from './pages/Register';
import RequestResetPassword from './pages/RequestResetPassword';
import LoginFailure from './pages/LoginFailure';
import StripeConfirmation from './pages/StripeConfirmation';
import StripeFailure from './pages/StripeFailure';

function App() {
  const [authState, setAuthState] = useState(AuthContextState);

  

  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Routes>
          <Route path="/reset-password/:userHash" element={<ResetPassword />} />
          <Route path="/reset-password" element={<RequestResetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login/success" element={<Login />} />
          <Route path="/login/failure" element={<LoginFailure />} />
          <Route path="/login/reset-success" element={<Login />} />
          <Route path="/stripeconfirmation" element={<StripeConfirmation />} />
          <Route path="/stripefailure" element={<StripeFailure />} />
            <Route path="/" element={<ProtectedRoute redirectTo="/" />}>
              <Route path="/" element={<Login />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="meetings" element={<Conversations />} />
              <Route path="meetings/:contentId" element={<Conversations />} />
              <Route path="documents" element={<Documents />} />
              <Route path="documents/:contentId" element={<Documents />} />
              <Route path="trash" element={<Trash />} />
              <Route path="my-profile" element={<MyProfile />} />
              <Route path="search" element={<Chat />} />
              <Route path="credits" element={<Credits />} />
              <Route path="pricing-plans" element={<PricingPlans />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthContext.Provider>
      <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          limit={1}
        />
        {/* Same as */}
    </BrowserRouter>
  );
}

export default App;
