import ContentLoader from "react-content-loader"


export default function ChatLoader({props}) {
  return (
    <ContentLoader 
    speed={2}
    width={340}
    height={20}
    viewBox="0 0 340 20"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="378" y="107" rx="3" ry="3" width="67" height="11" /> 
    <rect x="454" y="107" rx="3" ry="3" width="140" height="11" /> 
    <rect x="505" y="155" rx="3" ry="3" width="53" height="11" /> 
    <rect x="565" y="155" rx="3" ry="3" width="72" height="11" /> 
    <rect x="549" y="151" rx="3" ry="3" width="100" height="11" /> 
    <rect x="497" y="209" rx="3" ry="3" width="37" height="11" /> 
    <rect x="396" y="130" rx="3" ry="3" width="140" height="11" /> 
    <rect x="544" y="130" rx="3" ry="3" width="173" height="11" /> 
    <circle cx="436" cy="170" r="9" /> 
    <circle cx="534" cy="201" r="13" /> 
    <circle cx="562" cy="216" r="25" /> 
    <circle cx="9" cy="13" r="7" /> 
    <circle cx="463" cy="122" r="11" /> 
    <circle cx="30" cy="13" r="7" /> 
    <circle cx="51" cy="13" r="7" />
  </ContentLoader>
  )
}


