import React , {useState, useEffect} from 'react'

export default function EditableArea({text, handleUpdatedText, editable}) {
    const [updatedText, setUpdatedText] = useState(text) 

    
    function onUpdatedText(e){
        setUpdatedText(e.target.value)
        handleUpdatedText(e.target.value)
    }

    useEffect(() => {
      setUpdatedText(text)
    
      
    }, [text])
    

  return (
    <textarea
        value={updatedText}
        onChange={(e)=>onUpdatedText(e)}
        onClick={() => editable = !editable}
        disabled={!editable}
    ></textarea>
  )
}
