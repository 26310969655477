import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import purple from '../assets/vectors/purple.svg';
import blue from '../assets/vectors/blue.svg';
import green from '../assets/vectors/green.svg';


export default function LoginFailure() {

  return (
    <>
      <Navbar />
      <div className="login__container">
        <img className="purple" src={purple} alt="color"></img>
        <img className="green" src={green} alt="color"></img>
        <img className="blue" src={blue} alt="color"></img>
        <h1 className="login__title">Sorry, this e-mail confirmation request has expired</h1>
      </div>
      <Footer />
    </>
  );
}
