import { useParams } from "react-router";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import Navbar from "../components/Navbar";
import purple from '../assets/vectors/purple.svg';
import blue from '../assets/vectors/blue.svg';
import green from '../assets/vectors/green.svg';
import ResetPasswordForm from "../components/ResetPasswordForm";

export default function ResetPassword() {
  let { userHash } = useParams();


  return (
    <>
      <Navbar />
      <div className="login__container">
        <img className="purple" src={purple} alt="color"></img>
        <img className="green" src={green} alt="color"></img>
        <img className="blue" src={blue} alt="color"></img>
        <h1>Reset Password</h1>
        <ResetPasswordForm userHash={userHash}/>
      </div>
      <Footer />
    </>
  );
}
