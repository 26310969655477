import { useState, useContext, useEffect } from "react";
import Layout from "../components/Layout";
import recap from "../assets/vectors/hand.svg";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { getTransactions } from "../api/user";
import star from "../assets/vectors/star-icon.svg";
import creditCard from "../assets/vectors/credit-card-regular.svg";
import { formatDateTransaction } from "../utils/dates";

export default function Credits() {
  const { authState, setAuthState } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [popup, setPopup] = useState(false);
  const transactionsPerSection = 10;
  const [next, setNext] = useState(transactionsPerSection);

  function handleMoreTransactions() {
    setNext(next + transactionsPerSection);
  }


  async function getTransactionList() {
    const res = await getTransactions();
    if (res) {
      setTransactions(res);
    }
  }

  useEffect(() => {
    getTransactionList();
  }, []);

  return (
    <>
      <Layout>
        <div className="dashboard__container">
          <h3>Credits</h3>
          <div className="credits__box">
            <div className="credits__text">
              <p>
                {authState.firstName} {authState.lastName}, your available
                credits are:
              </p>
              <p className="credits">
                <span>{authState.currentCredits} </span>credits
              </p>
            </div>
            <Link to="/pricing-plans">
              <button className="black__button">Buy more credits</button>
            </Link>
          </div>
          {/* Transaction history */}
          <div className="transaction__list-container">
            <h3>Transaction history</h3>
            {transactions &&
              transactions?.slice(0, next)?.map((item, index) => {
                return (
                 
                    <div className="transaction__row-wrapper">
                      <div className="transaction__icon">
                        <img
                          src={item.icon === "credit-card" ? creditCard : star}
                          alt=""
                        ></img>
                      </div>
                      <div className="transaction__row" key={index}>
                          {/* date */}
                          <p className="transaction__date">
                            {formatDateTransaction(item.date)}
                          </p>
                          {/* job id */}
                          <p className="transaction__job">
                            {item.job_id}
                          </p>
                          {/* description */}
                          <div className="transaction__text">
                            <h5>{item.description}</h5>
                          </div>
                        
                        <span className="transaction__credits">
                          {item.credits}
                        </span>
                      </div>
                    </div>
              
                );
              })}
            {/* show more button */}
            {/* appears only if there are more than 10 transactions */}
            {next < transactions?.length && (
              <div
                className="show-more__button"
                onClick={handleMoreTransactions}
              >
                Show more
              </div>
            )}
          </div>
        </div>
        {/* {popup && (
          <div className="overlay">
            <div className="credits__popup">
              <img src={recap} alt="recapGPT"></img>
              <h4>Oops.</h4>
              <p>
                This function is not<br></br> available yet
              </p>
              <button
                className="credit__button"
                onClick={() => setPopup(false)}
              >
                Go back
              </button>
            </div>
          </div>
        )} */}
      </Layout>
    </>
  );
}
