import { useState, useEffect, useContext } from "react";
import { updateUser, getUserData } from "../api/user";
import Layout from "../components/Layout";
import logout from "../assets/vectors/logout.svg";
import userAvatar from "../assets/vectors/userAvatar.svg";
import { AuthContext } from "../context/AuthContext";

//Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";

export default function MyProfile() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    address: "",
    city: "",
    zipcode: "",
  });
  const [newAvatar, setNewAvatar] = useState("");
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);


  useEffect(() => {
    setFormData({
      ...formData,
      firstName: authState.firstName || "",
      lastName: authState.lastName || "",
      country: authState.country || "",
      address: authState.address || "",
      city: authState.city || "",
      zipcode: authState.zipcode || "",
    });
  }, [authState]);

  //handling form change
  function handleForm(event) {
    const name = event.target.name;
    const value = event.target.value;
    setFormData((values) => ({ ...values, [name]: value }));
  }
  //handling for submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateUser(formData);
      const updatedUser = await getUserData();
      const newAuthState = {
        ...authState,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        email: updatedUser.email,
        userId: updatedUser.userId,
        avatar: updatedUser.avatar,
        country: updatedUser.country,
        address: updatedUser.address,
        city: updatedUser.city,
        zipcode: updatedUser.zipcode,
      };
      setAuthState(newAuthState);
      localStorage.setItem("gpt-auth", JSON.stringify(newAuthState));
      toast.success("Data updated successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  //logging out
  function doLogOut() {
    localStorage.removeItem("gpt-auth");
    setAuthState({
      accessToken: null,
      isLoggedIn: false,
      firstName: null,
      lastName: null,
      email: null,
      userId: null,
      avatar: null,
      country: null,
      address: null,
      city: null,
      zipcode: null,
      videoModal: null,
    });
    navigate("/");
  }

  //handling avatar upload

  // function handleAvatarUpload(event){
  //   setNewAvatar(event.target.files[0])
  // }

  // async function  handleSubmitAvatar(event){
  //   event.preventDefault();
  //   const res = await fetch('http://localhost:3000', {
  //     headers: {
  //       'content-type': newAvatar.type,
  //       'content-length': `${newAvatar.size}`,
  //     }
  //   })
  //   const response = await res
  //   console.log(res)
  // }

  return (
    <>
      <Layout>
        {authState && (
          <div className="dashboard__container">
            <button className="black__button logout__button" onClick={doLogOut}>
              <img src={logout} alt="Log out"></img>Log out
            </button>
            <h3>My Profile</h3>
            <div className="profile__container">
              {/* avatar */}
              <img
                className="user__avatar"
                src={userAvatar}
                alt="user avatar"
              ></img>
              {/* <form>
                <input type="file" value={""} readOnly />
                <span>
                  Maximum file size. Feugiat iaculis eu rhoncus aliquam semper
                  suscipit urna. Condimentum pulvinar feugiat ipsum at.
                </span>
                <div className="submit__container">
                  <button className="black__button">Save</button>
                </div>
              </form> */}
              {/* name - surname */}
              <form className="user-data__form">
                <label htmlFor="firstName">Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  placeholder={formData.firstName}
                  onChange={handleForm}
                />
                <label htmlFor="lastName">Surname</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  placeholder={formData.lastName}
                  onChange={handleForm}
                />
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  placeholder={formData.country}
                  onChange={handleForm}
                />
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  placeholder={formData.address}
                  onChange={handleForm}
                />
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  placeholder={formData.city}
                  onChange={handleForm}
                />
                <label htmlFor="zipcode">Zipcode</label>
                <input
                  type="text"
                  name="zipcode"
                  value={formData.zipcode}
                  placeholder={formData.zipcode}
                  onChange={handleForm}
                />
                <div className="submit__container">
                  <span>Cancel</span>
                  <button className="black__button" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              </form>
              {/* email */}
              {/* update on second release? */}
              <form>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={authState.email}
                  placeholder={authState.email}
                  disabled
                />
              </form>
            </div>
          </div>
        )}

        {/* TOAST */}
        
      </Layout>
    </>
  );
}
